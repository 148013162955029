import './article_page.css';
import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import Study from './caso_studio.png';
import Advgdb from './gbd_vantage.png';

function Geodatabase() {

    const navigate = useNavigate(); // Create a navigate function

    const handleButtonClick = () => {
        navigate('/Login'); // Change to your desired URL
    };

    return(

        <div>
            <div className="home-title">
                    <h1> Geodatabase per l’Archiviazione delle Prove Geognostiche</h1>
            </div>

            <div className="geo-container-art">
                 <div className="image-carousel">
                    <div className="carousel-content">
                        <div className="image-and-description">
                            <img
                                src={Study} // Current image based on index
                                className="carousel-image"
                            />
                            <p className="image-description">
                                caso studio
                            </p>
                        </div>
                    </div>
                </div>

                <div className="im-i2">
                        <div className="title-im">
                           Cosa sono?
                        </div>
                        <div className="text-im-art">
                              I geodatabase sono sistemi di gestione dati progettati per l'archiviazione,
                              la gestione e l'analisi di dati spaziali e geografici. Utilizzati ampiamente in GIS (Sistemi Informativi Geografici),
                              consentono di memorizzare diversi tipi di dati spaziali, come immagini, tabelle e shapefile.
                              Il vantaggio di un geodatabase è che permette di organizzare i dati in modo strutturato,
                              facilitando il processo di analisi e visualizzazione delle informazioni georeferenziate.

                        </div>
                        <div className="title-im">
                           Perchè archiviare le prove in un Geodatabase?
                        </div>
                        <div className="text-im-art">
                           L'utilizzo di un geodatabase permette di organizzare i dati in modo strutturato, catalagando le prove ad esempio
                           per tipologia, località, profindità raggiunta. Inoltre permette di visualizzare le prove su mappa, rendnendole facilemnte accessibili
                           e permettendo di visualizzarne i risultati immediatamente. Altro aspetto molto importante è che gli utenti possono visualizzare i dati da qualsiasi
                           postazione ed in contemporanea migliorando la collaborazione del team. Da non sottovalutare è la possibilità di analisi e gestione dei dataset che
                           permette di creare mappe di pericolosità o estrarre informazioni d'insieme per l'area di interesse.
                        </div>
                        <div className="title-im">
                           Applicazioni
                        </div>
                        <div className="text-im-art">
                           Tutte le prove archiviate, risultano immediatamente disponibili e visualizzabili su mappa. Sono facilmente accessibili ed interrogabili
                           dagli utenti permettendo di effettuare rapide valutazioni in fase di preventivazione, elaborazione, realizzazione e confronto. Da non dimenticare
                           è la possibilità di realizzare cartografia dedicata associata ad esempio ai rischi come il rischio di potenziale liquefazione,
                           o al rischio sismico realizzando una carta delle Vs, eq.
                        </div>
                </div>
            </div>

            <div className="geo-container-art">
                 <div className="image-carousel">
                    <div className="carousel-content">
                        <div className="image-and-description">
                            <img
                                src={Advgdb} // Current image based on index
                                className="carousel-image"
                            />
                            <p className="image-description">
                                confrontro tra lavori eseguiti con applicazioni connesse ad un geodatabase e senza
                            </p>
                        </div>
                    </div>
                </div>

                <div className="im-i2">
                        <div className="title-im">
                           Caso studio
                        </div>
                        <div className="text-im-art">
                           Per una campagna di indagini che prevedeva la realizzazione di 10 prove sismiche tipo HVSR e MASW, 5 prove penetrometriche tipo CPTU
                           ed una prova CPT i due operatori in campo hanno utilizzato l'App mobile shnapp, per archiviare la posizione geografica delle singole prove
                           e scattare foto georeferenziate. In questo modo i dati vengono già archiviati e sono disponibili direttamente anche sulla piattaforma online shapp
                           la quale permette di visualizzare i dati, scaricarli o richiedere l'elaborazione come servizio a sofHare. In questo caso gli utenti si
                           sono avvalsi del servizio elaborazione prove. Attraverso il Plugin Geo Utilities è stato possibile elaborare ed archiviare i dati per gli utenti.
                           Gli stessi avrebbero potuto svolgere tale attività in autonomia sempre mediante il Plugin Geo Utilities, tuttavia è stato scelto il
                           servizio Snap ∩ Pro per risparmiare tempo e proseguire con le indagini. L'App mobile ha reso disponibili
                           i dati raccolti in tempo reale, la piattaforma shapp ha permesso di scaricare i dati archiviati ed il plugin Geo Utilities ha permesso di
                           elaborare e archiviare tutte le prove della campagna di indagini, tutto ciò è stato possibile grazie all'utilizzo del Geodatabase.
                           A termine è stata realizzata una carta dell'andamento delle Vs,eq e dell'indice di potenziale liquefazione con tools dedicati.
                        </div>
                        <div className="title-im">
                           Quali sono realmente i vantaggi?
                        </div>
                        <div className="text-im-art">
                           - <strong>La conoscenza preliminare dell'area</strong>, le prove già archiviate hanno permesso ai tecnici di optare per l'utilizzo del piezocono,
                             dato che la stratigrafia dell'area prevedeva alternanze tra sabbie e argille;
                           <br />
                           - <strong>Tempo e costi</strong> sono stati ridotti grazie all'utilizzo delle App e Plugin connessi al geodatabase;
                           <br />
                           - <strong>Riduzione degli errori</strong> gli strumenti forniti hanno permesso di archiviare ed elaborare quasi in tempo reale, evitando di perdere informazioni;
                           <br />
                           - <strong>Vista di insieme</strong> le carte di pericolosità hanno permesso di visualizzare e confrontare i dati;
                           <br />
                           - <strong>Archiviazione</strong> delle prove eseguite, che vanno a popolare il geodatabase personale degli utenti rendendo i dati disponibili e fruibili in futuro;
                        </div>
                </div>

            </div>
            <div className="descriptions">
            </div>
        </div>

    );
}


export default Geodatabase;
