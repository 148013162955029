import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import Shapp from './shapp-open.png';
import Down from './down_data.png';
import Arch from './archivio.png';

function Garage() {

    const navigate = useNavigate(); // Create a navigate function

    const user = JSON.parse(localStorage.getItem('user'));
    const username = user ? user.username : null;

    let customPath;
    if (username === "sofhare") {
        customPath = "sofhare_listafile";
    } else if (username === "servizi_geotecnici") {
        customPath = "servizi_geotecnici";
    } else if (username === "castellari_ambiente") {
        customPath = "Castellari_ambiente";
    } else if (username === "rdr_geo") {
        customPath = "rdr";
    }
    else {
        customPath = "null"; // Default path
    }

    const url = `https://www.repository.sofhare.com/${customPath}`;


    const handleButtonClickDown = () => {
        navigate('/area_download'); // Navigate to the link corresponding to the current image
    };

    const handleButtonClickShapp = () => {
        navigate('/sHapp'); // Navigate to the link corresponding to the current image
    };

    return(

        <div>
            <div className="home-title">
                    <h1> Cosa vuoi fare?</h1>
            </div>
            <br/>
            <div className="geo-container">
                <div className="image-carousel">
                    <div className="carousel-content">
                        <div className="image-and-description">
                            <img
                                src={Down} // Current image based on index
                                className="carousel-image"
                            />

                        <p className="image-blog-description clickable"
                            onClick={handleButtonClickDown} // Make description clickable
                         >
                            Area Download
                        </p>
                        </div>
                    </div>
                </div>

                <div className="image-carousel">
                    <div className="carousel-content">
                        <div className="image-and-description">
                            <img
                                src={Arch} // Current image based on index
                                className="carousel-image"
                            />
                            <p className="image-blog-description clickable"
                            onClick={() => window.open(url, '_blank')}
                         >
                            Archivio elaborazioni
                        </p>
                        </div>
                    </div>
                </div>

                <div className="image-carousel">
                    <div className="carousel-content">
                        <div className="image-and-description">
                            <img
                                src={Shapp} // Current image based on index
                                className="carousel-image"
                            />
                            <p className="image-blog-description clickable"
                            onClick={handleButtonClickShapp} // Make description clickable
                         >
                            sHapp
                        </p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="descriptions">
                
                <br/>
            </div>
        </div>

    );
}


export default Garage;

