import './expo_page.css';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Pay() {
  const [planInfo, setPlanInfo] = useState('');
  const [planType, setPlanType] = useState('Base');
  const [planPrice, setPlanPrice] = useState(149); // Make sure planPrice is a number
  // Taxes and additional fees
  const [marcaDaBollo, setMarcaDaBollo] = useState(2); // Fixed Marca da Bollo value
  const [contributoPostale, setContributoPostale] = useState(0);

  const location = useLocation();

  // Handling plan selection based on URL query parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const plan = params.get('plan');

    if (plan === 'pro') {
      setPlanInfo('Stai acquistando V.E.S. Pro');
      setPlanType('Pro');
      setPlanPrice(249); // Set to 249 for Pro plan as a number
    } else {
      setPlanInfo('Stai acquistando V.E.S. Base');
      setPlanType('Base');
      setPlanPrice(149); // Set to 149 for Base plan as a number
    }
  }, [location.search]);

  // Recalculate C.P. (Contributo Postale) whenever planPrice or Marca da Bollo changes
  useEffect(() => {
    const cp = 0.04 * (marcaDaBollo + planPrice);
    setContributoPostale(cp.toFixed(2)); // Round to 2 decimals
  }, [planPrice, marcaDaBollo]);

  // Total calculation (planPrice + marcaDaBollo + contributoPostale)
  const totalAmount = planPrice + marcaDaBollo + parseFloat(contributoPostale);

  return (
    <div>
      <div className="home-title">
        <h1>Check Out</h1>
      </div>
      <br />
      <div className="geo-container">
        <div className="tools">
          <section className="py-12 text-center">
            <div className="container mx-auto">
              <h1 className="title-im">{planInfo}</h1>

              <h1 className="text-im">Dati del Bonifico</h1>
              <ul className="text-left text-gray-600 mt-4">
                <li><strong>Intestatario:</strong> Dott. Geol. Grechi Umberto</li>
                <li><strong>IBAN:</strong> IT85X0200802414000420490415</li>
                <li><strong>Banca:</strong> Unicredit S.p.a.</li>
                <li><strong>Causale:</strong> Consulenza V.E.S. {planType}</li>
                <li>Importo: €{planPrice}</li>
                <li>Marca da Bollo: €{marcaDaBollo}</li>
                <li>C.P. (Contributo Postale): €{contributoPostale}</li>
                <li><strong>Totale:</strong> €{totalAmount.toFixed(2)}</li> {/* Total amount to be paid */}
              </ul>
              <div className="mt-6 inline-block px-8 py-3 bg-gray-800 text-white font-bold text-lg rounded-lg">
                  Per richiedere fattura o ulteriori informazioni, manda una email a <a href="mailto:umberto.grechi@sofhare.com">umberto.grechi@sofhare.com</a> con tutti i tuoi dati.
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Pay;



