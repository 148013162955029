import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import Sez from './Sezione.png';
import Sezo from './CPT_sez.png';
import SeisOver from './Sismic_overlap.png';
import CPT_see from './CPT_see.png';
import CPT_lito from './CPT_lito.png';
import CPT_graph from './CPT_graph.png';
import CPT_Over from './Overlap_data.png';


function Geoutilities() {

    const images = [
        CPT_graph, // Replace with your image URLs
        CPT_lito,
        CPT_see,
        Sezo,
        Sez,
        SeisOver
    ];

    const descriptions = [
        "Elaborazione prove penetrometriche con CPT Office - Grafici",
        "Elaborazione prove penetrometriche con CPT Office - SBT",
        "Visualizzazione prove penetrometriche Archiviate su Database",
        "Editing sezioni stratigrafiche tra prove penetrometriche",
        "Sezione stratigrafica da prove penetrometriche",
        "Archiviazione e visualizzazione prove sisiche",

    ];

    const [currentIndex, setCurrentIndex] = useState(0); // State to track the current image index

    // Function to go to the previous image
    const prevImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    // Function to go to the next image
    const nextImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const navigate = useNavigate(); // Create a navigate function

    const handleButtonDownl = () => {
        navigate('/newuser'); // Change to your desired URL
    };

    const handleButtonClick = () => {
        navigate('/elab_cpt'); // Change to your desired URL
    };

    return(

        <div>
            <div className="home-title">
                    <h1> Geo Utilities</h1>
            </div>
            <br/>
            <div className="geo-container">
                 <div className="image-carousel">
                    <button className="arrow left" onClick={prevImage}>
                        &#10094; {/* Left Arrow */}
                    </button>
                    <div className="carousel-content">
                        <div className="image-and-description">
                            <img
                                src={images[currentIndex]} // Current image based on index
                                alt={`Image ${currentIndex + 1}`}
                                className="carousel-image"
                            />
                            <p className="image-description">
                                {descriptions[currentIndex]}
                            </p>
                        </div>
                    </div>
                    <button className="arrow right" onClick={nextImage}>
                        &#10095; {/* Right Arrow */}
                    </button>
                </div>

                <div className="im-i2">
                        <div className="title-im">
                           Plugin QGis per:
                        </div>

                        <div className="text-im">
                           <br/>
                              - Elaborazione e archiviazione prove pentrometriche statiche CPT, CPTE e CPTU;
                           <br/>
                           <br/>
                              - Verifica dell'indice di potenziale liquefazione;
                           <br/>
                           <br/>
                              - Valutazione cedimenti post sismici;
                           <br/>
                           <br/>
                              - Elaborazione e archiviazione prove dilatometriche DMT e SDMT;
                           <br/>
                           <br/>
                              - Realizzazione sezioni di sondaggio;
                           <br/>
                           <br/>
                              - Realizzazione sezioni stratigrafiche tra prove penetrometriche e/o sondaggi;
                           <br/>
                           <br/>
                              - Archiviazione prove sismiche tipo HVSR, MASW e DH;
                           <br/>
                           <br/>
                              - Visualizzazione valori e grafici dei dati archiviati.
                        </div>
                        <br/>
                </div>
                 <div className="tools">
                    <div className="blog-preview">
                        <h3 className="preview-title">Blog - Elaborare una prova penetrometrica</h3>
                        <img src={CPT_Over} alt="Blog Preview" className="preview-image" />
                        <p onClick={handleButtonClick} className="preview-text">
                            Dettagli
                        </p>
                    </div>
                    <button onClick={handleButtonDownl} className="navigate-button">
                        Richiedi Plugin Free
                    </button>
                 </div>
            </div>
            <div className="descriptions">
                        <div className="title-desc">
                           Descrizione:
                        </div>
                        <div className="text-desc">
                              Geo Utilities è uno strumento indispensabile per coloro che operano nel settore geologico e geotecnico.
                              Dotato di interfaccia utente rapida ed intuitiva, permette di elaborare prove penetrometriche, dilatometriche e sondaggi
                              fornendo i principali parametri geotecnici in Output.
                              Tale strumento può essere connesso ad un database permettendo di archiviare le prove elaborate e
                              utilizzare gli strumenti per realizzare sezioni stratigrafiche, visualizzare i dati e stampare output.
                              Inoltre rende disponibile la possibilità di archiviare prove geofisiche, quali HVSR, MASW e DH, oltre che
                              viasualizzare e stampare output delle stesse.
                        </div>
                        <br/>
            </div>
        </div>

    );
}


export default Geoutilities;
