import './expo_page.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


function Pricing() {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/payment'); // Change to your desired URL
    };

    const handleButtonClickFree = () => {
        navigate('/contatti'); // Change to your desired URL
    };

    const handlePlanSelect = (plan) => {
        navigate(`/payment?plan=${plan}`); // Navigate to Pay page with query parameter 'plan'
    };

    return(

        <div>
            <div className="home-title">
                    <h1> V.E.S. Plan</h1>
            </div>
            <br/>

            <div className="geo-container">

                <div className="tools">
                  <div className="blog-preview">
                    {/* Pricing Table */}
                    <div className="pricing-table mt-6">
                      <h4 className="title-im">Geo Utilities Free</h4>
                      <table className="table-auto w-full border-collapse border border-gray-300 text-left">
                        <tbody>
                          <tr>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>✅</span>
                            </th>
                            <td className="text-im">
                              Geo Utilities Plugin
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>❌</span>
                            </th>
                            <td className="text-im">
                              Geodatabase
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>❌</span>
                            </th>
                            <td className="text-im">
                              Aggiornamenti software
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>❌</span>
                            </th>
                            <td className="text-im">
                              Videocorsi
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>❌</span>
                            </th>
                            <td className="text-im">
                              Supporto prima installazione
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>❌</span>
                            </th>
                            <td className="text-im">
                              Supporto tecnico completo
                            </td>
                          </tr>

                        </tbody>
                      </table>
                      <h4 className="title-im">0 € /anno</h4>
                      <br />

                      <button
                        onClick={handleButtonClickFree}
                        style={{
                          backgroundColor: "#12455b",
                          color: "white",
                          borderRadius: "50px", // Fully rounded
                          padding: "10px 20px", // Spacing inside the button
                          fontSize: "16px", // Font size
                          fontWeight: "bold", // Bold text
                          cursor: "pointer", // Pointer on hover
                          border: "none", // No border
                          transition: "background-color 0.3s ease", // Smooth hover effect
                        }}
                        onMouseOver={(e) => (e.target.style.backgroundColor = "#328ba8")}
                        onMouseOut={(e) => (e.target.style.backgroundColor = "#12455b")}
                      >
                        Ottieni Ora
                      </button>
                      <div style={{ marginBottom: "30px" }}></div>
                    </div>
                  </div>
                </div>


                <div className="tools">
                  <div className="blog-preview">
                    {/* Pricing Table */}
                    <div className="pricing-table mt-6">
                      <h4 className="title-im">V.E.S. Base</h4>
                      <table className="table-auto w-full border-collapse border border-gray-300 text-left">
                        <tbody>
                          <tr>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>✅</span>
                            </th>
                            <td className="text-im">
                              V.E.S. Suite
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>✅</span>
                            </th>
                            <td className="text-im">
                              Geodatabase
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>✅</span>
                            </th>
                            <td className="text-im">
                              Aggiornamenti software
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>✅</span>
                            </th>
                            <td className="text-im">
                              Videocorsi
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>✅</span>
                            </th>
                            <td className="text-im">
                              Supporto prima installazione
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>❌</span>
                            </th>
                            <td className="text-im">
                              Supporto tecnico completo
                            </td>
                          </tr>

                        </tbody>
                      </table>

                           <h4 className="title-im">149 € /anno</h4>
                          {/*
                          <h4 className="title-im">
                          <span style={{ textDecoration: 'line-through', color: 'gray' }}>149 € /anno</span><br />
                          <span style={{ color: 'red', marginLeft: '8px' }}>129 € /anno</span><br />
                          <span style={{ fontSize: '0.875rem', fontWeight: '600', color: 'black', marginLeft: '8px' }}>Black Friday</span>
                      </h4>*/}
                      <br />

                      <button
                        onClick={() => handlePlanSelect('base')}
                        style={{
                          backgroundColor: "#12455b",
                          color: "white",
                          borderRadius: "50px", // Fully rounded
                          padding: "10px 20px", // Spacing inside the button
                          fontSize: "16px", // Font size
                          fontWeight: "bold", // Bold text
                          cursor: "pointer", // Pointer on hover
                          border: "none", // No border
                          transition: "background-color 0.3s ease", // Smooth hover effect
                        }}
                        onMouseOver={(e) => (e.target.style.backgroundColor = "#328ba8")}
                        onMouseOut={(e) => (e.target.style.backgroundColor = "#12455b")}
                      >
                        Acquista Ora
                      </button>
                      <div style={{ marginBottom: "30px" }}></div>
                    </div>
                  </div>
                </div>

                <div className="tools">
                  <div className="blog-preview">
                    {/* Pricing Table */}
                    <div className="pricing-table mt-6">
                      <h4 className="title-im">V.E.S. Pro</h4>
                      <table className="table-auto w-full border-collapse border border-gray-300 text-left">
                        <tbody>
                          <tr>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>✅</span>
                            </th>
                            <td className="text-im">
                              V.E.S. Suite
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>✅</span>
                            </th>
                            <td className="text-im">
                              Geodatabase
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>✅</span>
                            </th>
                            <td className="text-im">
                              Aggiornamenti software
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>✅</span>
                            </th>
                            <td className="text-im">
                              Videocorsi
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>✅</span>
                            </th>
                            <td className="text-im">
                              Supporto prima installazione
                            </td>
                          </tr>

                          <tr>
                            <th className="text-im">
                              <span style={{ fontSize: "1.5rem", color: "green" }}>✅</span>
                            </th>
                            <td className="text-im">
                              Supporto tecnico completo
                            </td>
                          </tr>

                        </tbody>
                      </table>
                       <h4 className="title-im">249 € /anno</h4>
                      {/*<h4 className="title-im">
                          <span style={{ textDecoration: 'line-through', color: 'gray' }}>249 € /anno</span><br />
                          <span style={{ color: 'red', marginLeft: '8px' }}>219 € /anno</span><br />
                          <span style={{ fontSize: '0.875rem', fontWeight: '600', color: 'black', marginLeft: '8px' }}>Black Friday</span>
                      </h4>*/}
                      <br />

                      <button
                        onClick={() => handlePlanSelect('pro')}
                        style={{
                          backgroundColor: "#12455b",
                          color: "white",
                          borderRadius: "50px", // Fully rounded
                          padding: "10px 20px", // Spacing inside the button
                          fontSize: "16px", // Font size
                          fontWeight: "bold", // Bold text
                          cursor: "pointer", // Pointer on hover
                          border: "none", // No border
                          transition: "background-color 0.3s ease", // Smooth hover effect
                        }}
                        onMouseOver={(e) => (e.target.style.backgroundColor = "#328ba8")}
                        onMouseOut={(e) => (e.target.style.backgroundColor = "#12455b")}
                      >
                        Acquista Ora
                      </button>
                      <div style={{ marginBottom: "30px" }}></div>
                    </div>
                  </div>
                </div>

            </div>
        </div>

    );
}

export default Pricing;
